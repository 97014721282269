import '../css/packages.css';

export default function Packages() {
  return (
    <div className="packages" id="packages">
      <h1 className='outline'>OFERTA</h1>
      <div className="package_row">
        <div className='package'>
          <h2>PAKIET SILVER</h2>
          <h1>140 ZŁ</h1>
          <p>1 Trening Personalny</p>
        </div>
        <div className='package'>
          <h2>PAKIET GOLD</h2>
          <h1>500 ZŁ</h1>
          <p>4 Treningi Personalne</p>
        </div>
        <div className='package'>
          <h2>PAKIET DIAMOND</h2>
          <h1>900 ZŁ</h1>
          <p>8 Treningów Personalnych</p>
        </div>
      </div>
      <div className="option_row">
        <div className="option">
          <h1>
            Kształtowanie sylwetki
          </h1>
          <p>
            Pomogę Ci uzyskać wymarzoną sylwetkę. Pokażę Ci, że trening i dieta nie muszą być nudne.
          </p>
        </div>
        <div className="option">
          <h1>
            Fighter
          </h1>
          <p>
            Nauczę Cię boksu od podstaw. Pomogę poprawić Twoją kondycję i motorykę ciała.
          </p>
        </div>
      </div>
    </div>
  );
}