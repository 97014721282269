import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import '../css/footer.css';

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-map">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2550.6105340915337!2d19.03009027682049!3d50.2618579009246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716ce3475fc0e73%3A0xfaee8b2d92e941b0!2sal.%20Walentego%20Ro%C5%BAdzie%C5%84skiego%2014%2C%2040-201%20Katowice!5e0!3m2!1spl!2spl!4v1729545206498!5m2!1spl!2spl"
            width="100%"
            height="200"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
          ></iframe>
          <p className="footer-address">
            <b>
            Adress: al. Walentego Roździeńskiego 14, 40-203 Katowice <br />
            Siłownia Smart Gym Roździeńskiego
            </b>
          </p>
        </div>

        {/* Przed można wrzucić tego samego chłopka z logo bez podpisu kmwtw"*/}
        <div className="footer-info">
          <img className='logo' src="/images/logowhite3.png" alt="logo" />
          <div>
            <p>Przydatne linki:</p>
            <a href="https://www.instagram.com/sajmoon_97/" target="_blank" rel="noreferrer" className="social-link">
              <FontAwesomeIcon icon={faInstagram} className="social-icon" />
              <span>/sajmoon_97</span>
            </a>
            <a href="https://www.facebook.com/profile.php?id=100061182853213" target="_blank" rel="noreferrer" className="social-link">
              <FontAwesomeIcon icon={faFacebook} className="social-icon" />
              <span>/Sajmon Aesthetic</span>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-copyright">
        <p>&copy; 2024 Trener Sajmon. All Rights Reserved.</p>
      </div>
    </footer>
  );
}
