import { useRef, useState } from 'react';
import '../css/contact.css';

export default function Contact() {
  const nameRef = useRef<HTMLInputElement | null>(null);
  const emailRef = useRef<HTMLInputElement | null>(null);
  const phoneRef = useRef<HTMLInputElement | null>(null);
  const messageRef = useRef<HTMLTextAreaElement | null>(null);
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [phoneValue, setPhoneValue] = useState('');
  const [messageValue, setMessageValue] = useState('');

  const [responseMessage, setResponseMessage] = useState('');
  const [responseStatus, setResponseStatus] = useState('');

  // Validation functions
  const validateName = (name: string) => {
    return name.trim().length > 0;
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone: string) => {
    const phoneRegex = /^[0-9]+$/; // Only numbers
    return phone.trim().length > 0 && phoneRegex.test(phone);
  };

  const validateMessage = (message: string) => {
    return message.trim().length > 0;
  };

  const sendFormData = async () => {
    // Validate all fields
    if (!validateName(nameValue)) {
      setResponseMessage('Imię jest wymagane');
      setResponseStatus('fail');
      return;
    }

    if (!validateEmail(emailValue)) {
      setResponseMessage('Wprowadź poprawny adres e-mail');
      setResponseStatus('fail');
      return;
    }

    if (!validatePhone(phoneValue)) {
      setResponseMessage('Numer telefonu jest wymagany i musi zawierać tylko cyfry');
      setResponseStatus('fail');
      return;
    }

    if (!validateMessage(messageValue)) {
      setResponseMessage('Wiadomość nie może być pusta');
      setResponseStatus('fail');
      return;
    }

    const formData = new FormData();
    formData.append('name', nameValue);
    formData.append('email', emailValue);
    formData.append('phone', phoneValue);
    formData.append('message', messageValue);

    try {
      const response = await fetch('mail.php', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const result = await response.json();

        setResponseMessage(result.message);
        setResponseStatus(result.status);

        if (result.status === 'success') {
          nameRef.current!.value=''
          emailRef.current!.value=''
          phoneRef.current!.value=''
          messageRef.current!.value=''
          setNameValue('')
          setEmailValue('')
          setPhoneValue('')
          setMessageValue('')
        }
      } else {
        setResponseMessage('Wystąpił błąd po stronie serwera');
        setResponseStatus('fail');
      }
    } catch (error) {
      setResponseMessage('Wystąpił błąd połączenia');
      setResponseStatus('fail');
    }
  };

  return (
    <div className="contact" id="contact">
      <h1 className="outline">KONTAKT</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          sendFormData();
        }}
      >
        <div className={nameValue.length > 0 ? 'input active' : 'input'}>
          <input
            type="text"
            name="firstname"
            ref={nameRef}
            onChange={() => {
              setNameValue(nameRef.current!.value);
            }}
          />
          <span>Imię</span>
        </div>
        <div className={emailValue.length > 0 ? 'input active' : 'input'}>
          <input
            type="text"
            name="email"
            ref={emailRef}
            onChange={() => {
              setEmailValue(emailRef.current!.value);
            }}
          />
          <span>Adres e-mail</span>
        </div>
        <div className={phoneValue.length > 0 ? 'input active' : 'input'}>
          <input
            type="text"
            name="phonenumber"
            ref={phoneRef}
            onChange={() => {
              setPhoneValue(phoneRef.current!.value);
            }}
          />
          <span>Numer telefonu</span>
        </div>
        <div className={messageValue.length > 0 ? 'input active' : 'input'}>
          <textarea
            ref={messageRef}
            onChange={() => {
              setMessageValue(messageRef.current!.value);
            }}
          />
          <span>Wiadomość</span>
        </div>

        {responseStatus && (
          <div
            className={`response-message ${responseStatus === 'success' ? 'success' : 'fail'}`}
          >
            <p>{responseMessage}</p>
          </div>
        )}
        <button className="button" type="submit">
          Wyślij wiadomość
        </button>
      </form>

    </div>
  );
}
