import './App.css';
import Aboutme from './components/aboutme';
import Address from './components/address';
import Nav from './components/nav';
import Packages from './components/packages';
import Transformations from './components/transformations';
import Opinions from './components/opinions';
import Footer from './components/footer';
import Contact from './components/contact';
import ReviewsPage from './components/opinions';


function App() {
  return (
    <div className="App">
      <Nav/>
      <Aboutme/>
      {/* seba */}
      {/* <Opinions/> */}
      {/* kubot */}
      <Transformations/>
      {/* michu */}
      {/* <Address/> */}
      {/* michu */}
      <Packages/>
      <Contact/>
      {/* kubot */}
      <Footer/>
    </div>
  );
}

export default App;
