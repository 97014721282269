import '../css/transformations.css';
import justyna from "../transformations/justyna1.jpg";
import marcel from "../transformations/marcel.jpg";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

export default function Transformations() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    }
  };
  return (
    <div className='transformations' id="transformations">
      <h1 className="outline">TRANSFORMACJE</h1>
      <Carousel 
        swipeable={true}
        draggable={true}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlay={true} // Dodano tę właściwość
        autoPlaySpeed={5000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
      >
        <div className="transformation">
          <img src={justyna} alt="Transformacja Justyny" />
          <div>
            <h2>Justyna</h2>
            <span>Dzięki mojej pomocy osiągnęła wymarzoną sylwetkę</span>
          </div>
        </div>
        <div className="transformation">
          <img src={marcel} alt="Transformacja Marcela" />
          <div>
            <h2>Marcel</h2>
            <span>Dzięki mojej pomocy zmienił sylwetkę diametralnie</span>
          </div>
        </div>
      </Carousel>
    </div>
  );
}