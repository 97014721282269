import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../css/nav.css';
import { faBars, faPhone } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';

export default function Nav() {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const slideMenuRef = useRef<HTMLDivElement | null>(null);
  const [isMenuVisible, setMenuVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (menuRef.current) {
        const rect = menuRef.current.getBoundingClientRect();
        if (slideMenuRef.current) {
          if (rect.top < -50) {
            slideMenuRef.current.style.display = 'flex';
          } else {
            slideMenuRef.current.style.display = 'none';
          }
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };

  const scrollToSection = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, targetId: string) => {
    e.preventDefault();
    const targetElement = document.querySelector(targetId);
    if (targetElement) {
      let scrollOffset = targetElement.getBoundingClientRect().top + window.scrollY;

      if (targetId === '#aboutme') {
        scrollOffset += 100;
      } else {
        scrollOffset -= 120;
      }

      window.scrollTo({
        top: scrollOffset,
        behavior: 'smooth',
      });
    }
  };

  return (
    <>
      <div className='navcontainer' ref={menuRef}>
        <div className='logo'>
          <img src="/images/logowhite3.png" alt="logo" />
        </div>
        <div className='menu'>
          <FontAwesomeIcon icon={faBars} onClick={toggleMenu} />
        </div>
        <div className='submenu'>
          <a href="#aboutme" onClick={(e) => scrollToSection(e, '#aboutme')}>O mnie</a>
          <a href="#transformations" onClick={(e) => scrollToSection(e, '#transformations')}>Efekty</a>
          <a href="#packages" onClick={(e) => scrollToSection(e, '#packages')}>Cennik</a>
          <a href="#contact" onClick={(e) => scrollToSection(e, '#contact')}>Kontakt</a>
        </div>
        <div className='phone'>
          <a href="tel:536979286"><p><FontAwesomeIcon icon={faPhone}/> 536 979 286</p></a>
        </div>
      </div>
      <div className='navcontainer1' ref={slideMenuRef}>
        <div className='logo'>
          <img src="/images/logowhite3.png" alt="logo" />
        </div>
        <div className='menu'>
          <FontAwesomeIcon icon={faBars} onClick={toggleMenu} />
        </div>
        <div className='submenu'>
          <a href="#aboutme" onClick={(e) => scrollToSection(e, '#aboutme')}>O mnie</a>
          <a href="#transformations" onClick={(e) => scrollToSection(e, '#transformations')}>Efekty</a>
          <a href="#packages" onClick={(e) => scrollToSection(e, '#packages')}>Cennik</a>
          <a href="#contact" onClick={(e) => scrollToSection(e, '#contact')}>Kontakt</a>
        </div>
        <div className='phone'>
          <a href="tel:536979286"><p><FontAwesomeIcon icon={faPhone}/> 536 979 286</p></a>
        </div>
      </div>

      <div className={`${isMenuVisible ? 'show' : 'hidden'}`}>
        <a href="#aboutme" onClick={(e) => scrollToSection(e, '#aboutme')}>O mnie</a>
        <a href="#transformations" onClick={(e) => scrollToSection(e, '#transformations')}>Efekty</a>
        <a href="#packages" onClick={(e) => scrollToSection(e, '#packages')}>Cennik</a>
        <a href="#contact" onClick={(e) => scrollToSection(e, '#contact')}>Kontakt</a>
      </div>
    </>
  );
}
